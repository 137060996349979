import React from "react";
import connect1 from "../static/connect.jpg";
import connect2 from "../static/connect2.png";
import { Link } from "react-router-dom";
export default function Consultation() {
  return (
    <>
      <div
        className="card w-100 p-3"
        style={{ border: "none", borderRadius: "0px"}}
      >
        <div
          className="card w-100 p-3"
          style={{ border: "none", borderRadius: "0px" }}
        >
          <div className="row g-0">
            <div className="col-md-4">
              <img
                src={connect2}
                className="img-fluid rounded-start my-2"
                alt="..."
              />
            </div>
            <div className="col-md-8">
              <div className="card-body my-5 py-4">
                <h2 className="card-title fw-bold">
                  Elevate Your Business through Enhanced Cybersecurity
                  Excellence!"
                </h2>
                <p className="card-text my-3">
                  Unleash your business's true potential through the
                  reinforcement of a robust cybersecurity posture. Our holistic
                  solutions, coupled with strategic expertise, equip you to
                  confidently navigate the intricate digital landscape. By
                  bolstering your defenses, safeguarding sensitive data, and
                  ensuring uninterrupted operations, you'll achieve an elevated
                  level of cyber resilience. Seize control of your digital
                  destiny, empowering growth with a fortified cybersecurity
                  foundation that stands as a pillar of strength
                </p>
                <p className="card-text my-3">
                  At HACKCURA, we understand that in today's interconnected
                  world, cybersecurity isn't just a necessity – it's a catalyst
                  for innovation and expansion. By fortifying your digital
                  infrastructure, you're not only protecting your assets but
                  also creating a secure environment where your business can
                  thrive. Our dedicated team of experts is committed to
                  enhancing your cybersecurity ecosystem, providing you with the
                  confidence to explore new horizons, seize opportunities, and
                  build a future that's resilient, agile, and growth-focused.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={connect1}
              className="img-fluid rounded-start my-2"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body my-3 py-4">
              <h2 className="card-title fw-bold">
                Comprehensive Cybersecurity Solutions Under One Roof
              </h2>
              <p className="card-text my-3">
                From robust threat assessments to cutting-edge intrusion
                detection, our Hackcura team delivers a tailored approach that
                aligns seamlessly with your organization's security goals. We
                provide proactive monitoring, rapid incident response, and
                continuous innovation, assuring your data integrity and business
                continuity. With a holistic view of cybersecurity under our
                roof, you can focus on growth, knowing you have a steadfast
                guardian for your digital realm.
              </p>
              <button
                type="button"
                className="btn btn-primary  m-3 justify-content-center"
              >
                <Link className="nav-link" to="/contact">
                  Get A Free Consultation
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
