import IntroInfo from "./components/IntroInfo";
import NavBar from "./components/NavBar";
import WorkSteps from "./components/WorkSteps";
import WorkProvider from "./components/WorkProvider";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import ErrorPage from "./components/ErrorPage";
function App() {
  return (
    <div className="App" style={{ backgroundColor: "black" }}>
      <Router>
        <NavBar></NavBar>
        <Routes>
          <Route exact path="/" element={<IntroInfo />} />
          <Route path="/about" element={<About />} />
          <Route path="/worksteps" element={<WorkSteps />} />
          <Route path="/service" element={<WorkProvider />} />
          <Route path="/contact" element={<Contact />} />
            {/* Catch-all route for the error page */}
          <Route path="*" element={<ErrorPage />} />
          
        </Routes>
        <p className="p-1 bg-light text-center" >
          <small className="p-1 ">Copyright @2023 Hackcura. All Rights Reserved by Hackcura.</small>
        </p>
       
      </Router>
    </div>
  );
}

export default App;
