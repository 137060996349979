import React from "react";
import cardbg1 from "../static/cardbg1.png";

export default function Calculation() {
  return (
    <div>
      <div
        className="text-center align-middle text-white"
        style={{ marginTop: "30px", position: "relative" }}
      >
        <h2 className="fw-bold display-4">
          <span className="text-primary">
            20+ experts. 70+ global clients. 98% retention.
          </span>{" "}
          <p>Our track record speaks volumes</p>
        </h2>
      </div>

      <img
        src={cardbg1}
        className="card-img-top"
        style={{ height: "50px" }}
        alt="..."
      />
      <div className="text-center text-light d-none d-md-block">
        <p>Unleashing Cyber Resilience: Elevate Your Defenses with Hackcura!</p>
      </div>
    </div>
  );
}
