import React, { useEffect } from "react";
import service1 from "../static/service1.jpg";
import service2 from "../static/service2.jpeg";
import service3 from "../static/service3.jpeg";
import service4 from "../static/service4.jpeg";
import service5 from "../static/service5.jpeg";
import service6 from "../static/service6.jpeg";
import MoreInfo from "./MoreInfo";

export default function WorkProvider() {
  useEffect(() => {
    // Function to simulate clicking the "Next" button
    const clickNext = () => {
      const nextButton = document.querySelector(".carousel-control-next");
      if (nextButton) {
        nextButton.click();
      }
    };

    // Start the interval to click "Next" every 5 second (5000 milliseconds)
    const interval = setInterval(clickNext, 5000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <div style={{ width: "100%", borderRadius: "0px", border: "none" }}>
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-indicators my-2">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
          </div>
          <h3 className="m-4">
            <b className="text-primary">
              Delve into Our Cybersecurity Arsenal:{" "}
            </b>
            <b style={{ color: "white" }}>
              Unveiling Advanced Solutions and Tech-driven Offerings for
              Discerning Clients Seeking Unparalleled Protection.
            </b>
          </h3>

          <div className="carousel-inner" style={{ height: "700px" }}>
            <div className="carousel-item active">
              <div
                className="card text-bg-dark"
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  border: "none",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <img src={service1} className="card-img" alt="..." />
                <div className="card-img-overlay">
                  <h5 className="card-title">WEB APPLICATION PENETRATION</h5>
                  <p className="card-text text-white">
                    Web Application Penetration Testing is a systematic process
                    of identifying vulnerabilities and security weaknesses
                    within a web application. It involves conducting ethical
                    hacking to simulate real-world attacks, allowing security
                    experts to assess the application's security posture and
                    protect it from potential malicious threats. During the
                    testing process, security professionals use various tools
                    and methodologies to uncover common vulnerabilities like SQL
                    injection, cross-site scripting (XSS), and insecure
                    authentication mechanisms. They also analyze the
                    application's configuration, access controls, and data
                    handling practices.
                  </p>
                  <button type="button" className="btn btn-primary btn-sm">
                    <a
                      className="nav-link"
                      href="https://owasp.org/www-project-web-security-testing-guide/"
                    >
                      Read More
                    </a>
                  </button>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div
                className="card text-bg-dark"
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  border: "none",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <img src={service2} className="card-img" alt="..." />
                <div className="card-img-overlay">
                  <h5 className="card-title">
                    MOBILE APPLICATION PENETRATION TESTING
                  </h5>
                  <p className="card-text text-white">
                    The testing process includes examining the mobile app's
                    code, configurations, and backend APIs to identify potential
                    entry points for exploitation. Vulnerabilities like code
                    injection, insecure data storage, insecure communication,
                    and insufficient authentication are carefully analyzed.
                    Additionally, testers evaluate how the app handles sensitive
                    user information, ensuring it complies with privacy
                    regulations and best practices. Through the penetration
                    testing process, organizations receive a comprehensive
                    report outlining identified vulnerabilities, their severity
                    levels, and suggested remediation steps. This report assists
                    developers and stakeholders in prioritizing security
                    improvements to protect against cyber threats effectively.
                  </p>
                  <button type="button" className="btn btn-primary btn-sm">
                    <a
                      className="nav-link"
                      href="https://owasp.org/www-project-web-security-testing-guide/"
                    >
                      Read More
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div
                className="card text-bg-dark"
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  border: "none",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <img src={service3} className="card-img" alt="..." />
                <div className="card-img-overlay">
                  <h5 className="card-title">API PENETRATION TESTING</h5>
                  <p className="card-text text-white">
                    API Penetration Testing is a thorough assessment process
                    focused on identifying security vulnerabilities and
                    potential risks in Application Programming Interfaces
                    (APIs). It aims to ensure the integrity and confidentiality
                    of data exchanged between different software systems. During
                    API Penetration Testing, skilled security professionals,
                    known as penetration testers, simulate real-world attacks on
                    APIs to uncover weaknesses. They use a combination of
                    automated tools and manual techniques to assess the API's
                    security controls and authentication mechanisms. The testing
                    process involves analyzing API endpoints, request and
                    response structures, and data handling practices. Testers
                    also evaluate how the API handles input validation,
                    authentication, and authorization to prevent unauthorized
                    access and data exposure.
                  </p>
                  <button type="button" className="btn btn-primary btn-sm">
                    <a
                      className="nav-link"
                      href="https://owasp.org/www-project-api-security/"
                    >
                      Read More
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div
                className="card text-bg-dark"
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  border: "none",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <img src={service4} className="card-img" alt="..." />
                <div className="card-img-overlay">
                  <h5 className="card-title">NETWORK PENETRATION TESTING</h5>
                  <p className="card-text text-white">
                    Through various methodologies like vulnerability scanning,
                    penetration testers identify common network vulnerabilities
                    such as outdated software, default configurations, and
                    unpatched systems. Additionally, testers simulate various
                    attack scenarios, such as Denial of Service (DoS) attacks,
                    Man-in-the-Middle (MitM) attacks, and intrusion attempts, to
                    evaluate the network's resilience against these threats.
                    Upon completion of the testing, a comprehensive report is
                    provided, detailing identified vulnerabilities, their
                    severity levels, and recommended remediation steps. This
                    report assists network administrators and IT teams in
                    prioritizing security improvements and implementing
                    effective defenses. Regular Network Penetration Testing is
                    essential to maintain a strong and secure network
                    environment. By proactively detecting and addressing
                    security weaknesses, organizations can prevent potential
                    data breaches, safeguard sensitive information, and ensure
                    the network's integrity and availability.
                  </p>
                  <button type="button" className="btn btn-primary btn-sm">
                    <a
                      className="nav-link"
                      href="https://owasp.org/www-project-web-security-testing-guide/latest/3-The_OWASP_Testing_Framework/1-Penetration_Testing_Methodologies"
                    >
                      Read More
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div
                className="card text-bg-dark"
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  border: "none",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <img src={service5} className="card-img" alt="..." />
                <div className="card-img-overlay">
                  <h5 className="card-title">
                    SERVER AND CLOUD SECURITY AUDITS
                  </h5>
                  <p className="card-text text-white">
                    Server and Cloud Security Audits are comprehensive
                    assessments conducted to evaluate the security measures and
                    vulnerabilities present in server infrastructure and
                    cloud-based services. The primary goal is to ensure the
                    confidentiality, integrity, and availability of data and
                    resources hosted on servers or cloud platforms. During
                    Server and Cloud Security Audits, security experts
                    thoroughly review server configurations, access controls,
                    and data handling practices. In cloud environments, they
                    assess the security of cloud service configurations, network
                    controls, and identity management. The auditing process
                    involves analyzing server settings, operating system
                    security patches, and service configurations. For cloud
                    services, it includes evaluating compliance with security
                    best practices and industry standards.
                  </p>
                  <button type="button" className="btn btn-primary btn-sm">
                    <a
                      className="nav-link"
                      href="https://owasp.org/www-project-web-security-testing-guide/latest/3-The_OWASP_Testing_Framework/1-Penetration_Testing_Methodologies"
                    >
                      Read More
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div
                className="card text-bg-dark"
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  border: "none",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <img src={service6} className="card-img" alt="..." />
                <div className="card-img-overlay">
                  <h5 className="card-title">SOURCE CODE REVIEW</h5>
                  <p className="card-text text-white">
                    Source Code Review, also known as Code Review or Static Code
                    Analysis, is a process of systematically inspecting the
                    source code of software applications to identify potential
                    security vulnerabilities, coding errors, and performance
                    issues. It is a crucial part of the software development
                    lifecycle and aims to ensure the quality and security of the
                    codebase. During Source Code Review, skilled developers or
                    security experts carefully analyze the source code line by
                    line, looking for programming mistakes, adherence to coding
                    standards, and potential security weaknesses. The review
                    process is typically manual, although automated tools can
                    also assist in identifying common coding errors and security
                    flaws.
                  </p>
                  <button type="button" className="btn btn-primary btn-sm">
                    <a
                      className="nav-link"
                      href="https://owasp.org/www-project-code-review-guide/"
                    >
                      Read More
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <MoreInfo></MoreInfo>
    </>
  );
}
