import React from "react";
import card1 from "../static/card1.jpg";
import card2 from "../static/card2.jpg";
import card3 from "../static/card3.jpg";
import card4 from "../static/card4.svg";
import card5 from "../static/card5.jpg";
import card6 from "../static/card6.png";
import cardbg1 from "../static/cardbg1.png";
import MoreInfo from "./MoreInfo";
import './styles.css'; // Import the CSS file

export default function WorkSteps() {
  return (
    <>
      <div>
        <div
          className="text-center align-middle text-white content-hover-animation"
          style={{ marginTop: "30px", position: "relative" }}
        >
          <h2 className="fw-bold display-4">
            What's new at<span className="text-primary"> Hackcura</span>
          </h2>
          <p>
            Empowering your digital landscape with next-level defenses and
            unparalleled peace of mind.
          </p>
        </div>

        <img
          src={cardbg1}
          className="card-img-top"
          style={{ height: "70px" }}
          alt="..."
        />
      </div>
      <div
        className="card"
        style={{
          width: "100%",
          borderRadius: "0px",
          margin: "0px",
          border: "none",
          overflow: "auto",
        }}
      >
        <div className="container">
          <div className="row my-4">
            <div className="col-md-4 my-3">
              <div className="card border-0 shadow-lg overflow-hidden card-hover-animation">
                <img
                  src={card1}
                  className="card-img-top"
                  style={{ height: "300px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Reconnaissance And Planning</h5>
                  <p className="card-text">
                    This the phase of Information Gathering and mapping of
                    organization’s infrastructure.And analysis of the
                    infrastructure.
                  </p>
                  <a href="/" className="btn btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-3 ">
              <div className="card border-0 shadow-lg overflow-hidden card-hover-animation">
                <img
                  src={card2}
                  className="card-img-top"
                  style={{ height: "300px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Vulnerability Assessment</h5>
                  <p className="card-text">
                    In this stage, we run vulnerability scanners to look for
                    common vulnerabilities and possible vulnerabilities related
                    to the platform.
                  </p>
                  <a href="/" className="btn btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-3 mb-2">
              <div className="card border-0 shadow-lg overflow-hidden card-hover-animation">
                <img
                  src={card3}
                  className="card-img-top"
                  style={{ height: "300px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Penetration Testing</h5>
                  <p className="card-text">
                    Here, we run exploits on the application to guage its
                    security. We use custom scripts & in-house tools to attain
                    high degree of penetration.
                  </p>
                  <a href="/" className="btn btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-3 ">
              <div className="card border-0 shadow-lg overflow-hidden card-hover-animation ">
                <img
                  src={card4}
                  className="card-img-top"
                  style={{ height: "300px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Reporting & Discussion</h5>
                  <p className="card-text">
                    Generate the reports of vulnerabilities discovered along
                    side discussion on type of vulnerability, its impact and
                    threat level and their impact scenario with the client
                    developer's.
                  </p>
                  <a href="/" className="btn btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-3 ">
              <div className="card border-0 shadow-lg overflow-hidden card-hover-animation ">
                <img
                  src={card5}
                  className="card-img-top"
                  style={{ height: "300px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Security Remediation</h5>
                  <p className="card-text">
                    Our laborative team works closely with your developers and
                    IT personnel to implement essential fixes and enhancements
                    and fortifying your digital assets.
                  </p>
                  <a href="/" className="btn btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-3 ">
              <div className="card border-0 shadow-lg overflow-hidden card-hover-animation mb-2">
                <img
                  src={card6}
                  className="card-img-top"
                  style={{ height: "300px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Post-Remediation Validation</h5>
                  <p className="card-text">
                    {" "}
                    In this final step, we re-evaluate the application's
                    security posture to ensure potential threats are addressed
                    effectively, providing you with a fortified and secure
                    digital environment.
                  </p>
                  <a href="/" className="btn btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MoreInfo />
    </>
  );
}
