import React from "react";
import Form from "./Form";
import MoreInfo from "./MoreInfo";
import './styles.css'; // Import the CSS file

export default function Footer() {
  return (
    <>
      <div
        id="footer"
        className="card w-100 bg-light py-3"
        style={{
          height: "100%",
          borderRadius: "0px",
          margin: "0px",
        }}
      >
        <h1 className="display-4 p-4 text-center">
          <b>Questions? <span className="icon">We have answers.</span></b>
        </h1>

        <p className="text-center icon">
          How else can we help? Let us know and we’ll get in touch.
        </p>
        <div className="d-flex justify-content-center">
          <Form></Form>
        </div>
      </div>
      <MoreInfo></MoreInfo>
    </>
  );
}
