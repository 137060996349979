import React from "react";
import team1 from "../static/team1.jpeg";
import team2 from "../static/team2.jpeg";
import team3 from "../static/team3.jpeg";

import Icon from "@mdi/react";
import { mdiLinkedin, mdiTwitter } from "@mdi/js";

export default function Team() {
  return (
    <div
      className="card bg-light"
      style={{
        width: "100%",
        borderRadius: "0px",
        margin: "0px",
        border: "none",
        overflow: "auto",
      }}
    >
      <div className="container">
        <h1 className="text-center m-4 fw-bold">Our Leadership Team</h1>
        <div className="row my-4">
          <div className="col-md-4">
            <div className="card bg-light border-0 shadow-lg overflow-hidden card-hover-animation" style={{ border: "none" }}>
              <img
                src={team1}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title fw-bold">Udit Bhaduria</h5>
                <p className="p-1 ">
                <a href="https://www.linkedin.com/in/udit-thakkur/">
                  <Icon
                    className="p-1"
                    path={mdiLinkedin}
                    size={1}
                    title="LinkedIn"
                  /></a>
                   <a href="https://twitter.com/udit_thakkur">
                  <Icon
                    className="p-1"
                    path={mdiTwitter}
                    size={1}
                    title="Twitter"
                  /></a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className="card bg-light border-0 shadow-lg overflow-hidden card-hover-animation" style={{ border: "none" }}>
              <img
                src={team2}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title fw-bold">Aditya Raj Singh</h5>
                <p className="p-1 ">
                 
                <a href="https://www.linkedin.com/in/adityarajawat77/">
                  <Icon
                    className="p-1"
                    path={mdiLinkedin}
                    size={1}
                    title="LinkedIn"
                  /></a>
                   <a href="https://twitter.com/adityarajawat77">
                  <Icon
                    className="p-1"
                    path={mdiTwitter}
                    size={1}
                    title="Twitter"
                  /></a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="card bg-light border-0 shadow-lg overflow-hidden card-hover-animation" style={{ border: "none" }}>
              <img
                src={team3}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title fw-bold">Ashish Kunwar </h5>
                <p className="p-1 ">
                 <a href="https://www.linkedin.com/in/ashish-kunwar-0ab509194/">
                  <Icon
                    className="p-1"
                    path={mdiLinkedin}
                    size={1}
                    title="LinkedIn"
                  /></a>
                   <a href="https://twitter.com/d0rkerdevil">
                  <Icon
                    className="p-1"
                    path={mdiTwitter}
                    size={1}
                    title="Twitter"
                  /></a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
