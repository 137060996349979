import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyB8zPY1sKT0JUqWybET5SOqSniTmtAe4dE",
  authDomain: "hackcura-web.firebaseapp.com",
  databaseURL: "https://hackcura-web-default-rtdb.firebaseio.com",
  projectId: "hackcura-web",
  storageBucket: "hackcura-web.appspot.com",
  messagingSenderId: "618048565124",
  appId: "1:618048565124:web:b88ea5d7ebe7f20c54f7d1",
  measurementId: "G-N6FT5KLL6F"
};
  
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  
  export { app, database };