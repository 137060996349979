import React from "react";
import { Link } from "react-router-dom";
import cardbg1 from "../static/cardbg1.png";
import MoreInfo from "./MoreInfo";
import Consultation from "./Consultation";
import Calculation from "./Calculation";

export default function IntroInfo() {
  return (
    <>
      <div
        className="card"
        style={{
          backgroundColor: "black",
          width: "100%",
          height: "870px",
          borderRadius: "0px",
        }}
      >
        <div style={{ position: "relative", top: "7%" }}>
          <div className="card-header text-center">
            <h1 className="display-3 text-light fw-bold">
              Stay secure round-the-clock, matching your organization's
              unwavering commitment with steadfast,{" "}
              <span className="text-primary text-opacity-75">
                continuous protection.
              </span>
            </h1>
          </div>
          <div className="card-body">
            <h6 className="text-center fw-medium text-secondary">
              Together with our community of cyber warriors, we strive to
              achieve these goals and lead the way towards a more secure and
              resilient digital future. Welcome to Hackcura, where we transform
              security challenges into opportunities for growth and empowerment.
            </h6>

            <div className="d-flex justify-content-center py-3">
              <button
                type="button"
                className="btn btn-secondary m-3 justify-content-center"
              >
                <Link className="nav-link" to="/service">
                  Explore More
                </Link>
              </button>
              <button
                type="button"
                className="btn btn-primary  m-3 justify-content-center"
              >
                <Link className="nav-link" to="/contact">
                  Get In Touch
                </Link>
              </button>
            </div>
          </div>
        </div>
        <img src={cardbg1} className="card-img-bottom w-100" alt="..." />
      </div>
      <Consultation></Consultation>
      <Calculation></Calculation>
      <MoreInfo></MoreInfo>
    </>
  );
}
