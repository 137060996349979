import React from "react";
import cardbg1 from "../static/cardbg1.png";
import MoreInfo from "./MoreInfo";
import Team from "./Team";
import Consultation from "./Consultation";

export default function About() {
  return (
    <>
      <div
        className="card"
        style={{
          backgroundColor: "black",
          width: "100%",
          height: "800px",
          borderRadius: "0px",
        }}
      >
        <div className="card-header m-4">
          <h3>
            <b style={{ color: "white" }}>
              We Are A Cyber Security Organization Providing End-To-End Security
              Solutions And Consultancy. We Provide High End Security Services
              In
            </b>
            <b className="text-primary">
              {" "}
              Vulnerability Assessment & Penetration Testing.
            </b>
          </h3>
        </div>
        <div className="card-body m-3">
          <p className="card-text text-white">
            Hackcura is a team of enthusiastic and passionate security testers.
            Our team comprises industry level well experienced security testers
            who are accredited by Government Of India and well recognised by
            various organizations. We have a security model of NGPT (Next
            Generation Penetration Testing), while other companies are still
            following the old conventional methods for Testing. We as a company
            are focused on providing the best services, as minimal as possible
            cost with the motive of WIN-WIN situation for all, customer as well
            as the service provider. The idea is to build trust and relationship
            with Indian Companies to secure the Indian Cyber Space under the
            name of HACKCURA <span className="text-primary">(Cure for the hacks)</span>.
          </p>
          <button type="button" className="btn btn-primary btn-sm">
            <a
              className="nav-link"
              href="https://owasp.org/www-project-web-security-testing-guide/"
            >
              Read More
            </a>
          </button>
        </div>
        <img src={cardbg1} className="card-img-bottom" alt="..." />
      </div>
      <Team></Team>
      <Consultation></Consultation>
      <MoreInfo></MoreInfo>
    </>
  );
}
