import React from "react";
import logo from "../static/logo.png";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg py-4"
        data-bs-theme="dark"
        style={{ backgroundColor: "black" }}
      >
        <div className="container">
          <a className="navbar-brand d-flex" href="/">
            <img
              src={logo}
              alt="Logo"
              width="200"
              height="70"
              className="justify-content-center"
            />
          </a>
          <div>
            <ul className="nav mx-2 fw-bold">
              <li className="nav-item">
                <Link className="nav-link text-light icon" to="/worksteps">
                  How It Works
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-light icon" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-light icon" to="/service">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-light icon" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
