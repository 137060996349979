import React from "react";
import cardbg1 from "../static/cardbg1.png";
import Footer from "./Footer";
import './styles.css'; // Import the CSS file
export default function Contact() {
  return (
    <>
      <div>
        <div
          className="text-center align-middle text-white justify-center content-hover-animation"
          style={{ marginTop: "10%", position: "relative" }}
        >
          <h2 className="fw-bold">
            We’d <span className="text-primary">Love</span> to hear from you!
          </h2>
          <h5 className="text-light my-3">
            Fill in the form below and we'll get back to you as soon as we can!
          </h5>
        </div>

        <img
          src={cardbg1}
          className="card-img-top"
          style={{ height: "400px" }}
          alt="..."
        />
      </div>
      <Footer></Footer>
    </>
  );
}
